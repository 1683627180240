@use "styles/typography";
@use "styles/colors";
.notification-content {
    display: flex;
    justify-content: space-between;
    div {
        display: flex;
    }
    .notification-image {
        width: 88px;
        border-radius: 4px;
    }
    .small-not-container {
        min-width: 250px;
        margin-right: 5%;
        margin-left: 15px;
        flex-direction: column;
        justify-content: space-evenly;
        .icon-info {
            display: flex;
            align-items: center;
        }
        .check-circle {
            margin-right: 7px;
            color: colors.$color-success-active;
        }
    }
    .bold {
        font-weight: typography.$bold;
        font-size: typography.$header-s;
    }
    .button {
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        Button {
            width: 150px;
            height: 40px;
        }
    }
}