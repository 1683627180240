@use "styles/typography";
@use "styles/colors";
.card {
  border-radius: 12px;
  box-shadow: 0px 8px 16px #f0f0f0;
  padding: 32px;
}

.no-shadow {
  border: 1px solid colors.$color-semantic-primary;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.grey-card {
  background-color: colors.$color-background-02;
  padding: 21px;
  margin-bottom: 15px;
  border-radius: 7px;
}

.red-card {
  background-color: #ffe6e5;
  padding: 21px;
  margin-bottom: 15px;
  border-radius: 7px;
}

.blue-card {
  border: 1px solid colors.$color-semantic-primary;
  border-radius: 4px;
  padding: 15px;
  background-color: colors.$light-blue;
}

.blue-right-card {
  border: 1px solid colors.$color-semantic-primary;
  border-radius: 4px;
  padding: 21px;
  background-color: colors.$light-blue;
  float: right;
  width: 100%;
}

.input-card {
  border: 1px solid colors.$color-semantic-primary;
  border-radius: 0px;
  padding: 15px;
  width: 100%;
}
