@use "styles/typography";
.body {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
  Button {
    margin-top: 16px;
    width: 100%;
  }
}

.options-subtitle.options-subtitle {
  margin-bottom: 15px;
  font-size: typography.$body-m;
  padding-top: 6px;
  color: #808080;
}

.options {
  width: 40%;
}

.option-detail {
  width: 58%;
}

.info {
  margin-bottom: 24px;
}

.info-subtitle.info-subtitle {
  margin-top: -55px;
}

.no-option-wrapper {
  margin: 40px auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-option-message {
  color: #808080;
  font-weight: bold;
}

.image {
  width: 330px;
}

.red-text {
  font-size: 12px;
  color: red;
}

.blue-text {
  font-size: 12px;
  color: blue;
}

.subsection-title.subsection-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.actions {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
