@use "styles/typography";
@use "styles/colors";

.primary-column-present {
  display: flex;
  justify-content: space-between;

  .primary-column {
    width: 60%;
    margin-bottom: 8px;
    align-items: flex-start;
  }

  .columns {
    width: 40%;
  }
}

.columns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.check-divider {
  margin: 0px -15px;
}
