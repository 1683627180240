@use "styles/colors";
@use "styles/typography";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.input-container {
  height: 40px;
  border-radius: 4px;
  margin-top: 8px;
  -webkit-appearance: none;
  cursor: pointer;
  border: solid 1px #666666;
  position: relative;
  display: flex;
  align-items: center;

  select {
    width: 100%;
    border: none;
    padding: 9px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    margin-right: -24px;
  }

  .arrow {
    right: 10px;
    pointer-events: none;
  }
}

.input-error {
  border: solid 1px colors.$error;
}

.error-text {
  margin-top: 10px;
  color: colors.$error;
  font-size: typography.$body-s;
}
