@use "styles/typography";
.body {
    display: flex;
    justify-content: space-between;
    p {
        margin: 0;
    }
    Button {
        margin-top: 16px;
        width: 100%;
    }
}

.products {
    margin-right: 24px;
}

.product {
    margin-bottom: 24px;
}

.price-container {
    width: 25vw;
    max-width: 417px;
    min-width: 230px;
}

.total-header {
    display: flex;
    justify-content: space-between;
    font-size: typography.$header-s;
    font-weight: typography.$bold;
}

.sticky{
    position: sticky;
    top:15vw;
}