@use "styles/typography";
@use "styles/colors";
.product {
  min-width: 610px;
  p {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin-bottom: 24px;
  }
  .body {
    display: flex;
    justify-content: space-between;
    .image {
      width: 140px;
      height: 140px;
    }
    .properties {
      display: flex;
      width: 55%;
      img {
        margin-right: 27px;
      }
      .properties-title {
        font-size: typography.$body-m;
        font-weight: typography.$bold;
      }
      .property {
        font-size: typography.$body-s;
        color: colors.$light;
        line-height: 24px;
      }
    }
    .options {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      .quantity-wrapper {
        display: block;
        .unit-container {
          display: flex;
          align-items: center;
        }
        input {
          height: 40px;
          border: solid 1px #666666;
          border-radius: 4px;
          padding: 9px;
        }
      }
      .buttons-wrapper {
        display: flex;
      }
      .icon {
        font-size: typography.$body-l;
      }
      Button {
        font-size: typography.$body-l;
        width: fit-content;
        padding: 0;
        margin-left: auto;
      }
    }
  }
}
