@use "styles/typography";
@use "styles/colors";

.banner-message {
  align-items: flex-start;
}

.banner-title {
  font-size: typography.$body-l;
  font-weight: typography.$bold;
  margin-bottom: 8px;
}

.link {
  margin-top: 12px;
  color: colors.$text-link;
  text-decoration: none;
  background-color: transparent;
  font-size: typography.$body-m;
}

.link:hover {
  color: colors.$text-link-hover;
  text-decoration-line: underline;
}
