@use "styles/colors";
@use "styles/typography";

.body {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
  Button {
    margin-top: 16px;
    width: 100%;
  }
}

.options-subtitle {
  font-size: typography.$body-m;
  padding-top: 6px;
  margin-bottom: 15px !important;
  color: #808080;
}

.options {
  width: 40%;
}

.option-detail {
  width: 58%;
}

.sub-label {
  height: 15px;
  width: 395px;
}

.input-radio {
  vertical-align: text-bottom;
  cursor: pointer;
}

.input-tag {
  height: 20px;
  width: 235px;
  display: inline-block;
  margin-right: 20px;
}

.offer-head {
  margin-bottom: 8px;
  width: 430px;
}

.eta {
  height: 20px;
  width: 150px;
  display: inline-block;
}

.first-card {
  margin-bottom: 24px;
}

.product-wrapper {
  margin-bottom: 24px;
}

.product {
  min-width: 570px;
  width: 49.5vw;
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
    .item-title {
      height: 20px;
      width: 300px;
    }
    .item-price {
      height: 20px;
      width: 80px;
      margin-right: 23px;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    .image {
      width: 140px;
      height: 140px;
    }
    .properties {
      display: flex;
      width: 55%;
      .image-product {
        height: 100px;
        width: 100px;
        margin-right: 27px;
      }
      .properties-title {
        height: 25px;
        width: 200px;
      }
      .properties-text {
        height: 20px;
        width: 100px;
        margin: 3px 0px;
      }
    }
    .options {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      .quantity-wrapper {
        display: block;
        .quantity-text {
          height: 20px;
          width: 50px;
          margin-bottom: 3px;
        }
        .unit-container {
          display: flex;
          align-items: center;
          height: 20px;
          width: 150px;
        }
      }
      .buttons-wrapper {
        height: 20px;
        width: 80px;
        margin-left: auto;
      }
    }
  }
}

.total-price {
  height: 25px;
  width: 180px;
}

.sub-description {
  height: 10px;
  width: 130px;
  margin-bottom: 12px !important;
}

.description {
  height: 15px;
  width: 680px;
  margin-bottom: 10px !important;
}

.green-text {
  height: 10px;
  width: 110px;
  margin-top: 3px !important;
  margin-bottom: 8px !important;
}

.loading-card {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  float: right;
}

.data-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.left-text {
  height: 20px;
  width: 70px;
}

.right-text {
  height: 20px;
  width: 100px;
}

.total-wrapper {
  height: 25px;
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.right-wrapper {
  float: right;
}

.loading {
  background: linear-gradient(-45deg, #ffffff, rgb(219, 214, 214));
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
