@use "styles/typography";
@use "styles/colors";

.summary-container {
  .summary-title {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin-bottom: 10px;
  }
  .summary-title-red {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin-bottom: 10px;
    color: #ffc0bd;
  }
  .summary-message-red {
    font-weight: typography.$bold;
    color: #c9423c;
    margin-bottom: 10px;
  }
  .summary-characteristic-red {
    color: #ffc0bd;
  }

  max-width: 300px;
  min-width: 276px;
  height: fit-content;
}
