@use "styles/typography";
@use "styles/colors";

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  .input-box {
    flex-grow: 1;
    height: 40px;
    border: solid 1px colors.$color-button-disabled;
    border-radius: 4px;
    padding: 10px;
    background-color: colors.$white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .unit {
      background-color: colors.$light-blue;
      width: fit-content;
      color: colors.$neutral-700;
      line-height: 20px;
      font-size: 12px;
      padding: 4px 16px;
      border-radius: 16px;
      font-weight: typography.$semi-bold;
    }
    .disable {
      background-color: colors.$color-button-disabled;
      color: colors.$neutral-400;
    }
  }
  Button {
    padding: 11px;
  }

  .back-icon {
    font-size: typography.$body-l;
  }
}

.quantity-container {
  font-size: typography.$body-l;

  .quantity-title {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin-bottom: 4px;
  }
  .quantity-title-disabled {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin-bottom: 4px;
    color: #b3b3b3;
  }
  .quantity-label-disabled {
    color: #b3b3b3;
  }
}
