@use "styles/colors";

.body {
  min-height: 75vh;
  padding: 0 4.782vw;
  margin-bottom: 10px;
  p {
    margin: 0;
  }
}
