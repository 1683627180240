@use "styles/typography";
@use "styles/colors";

.cards-row, .open-pay-info-row {
    display: flex;
    flex-direction: row;
}

.credit-card-logos-column {
    width: 30%;
    align-items: flex-start;
}

.debit-card-logos-column {
    width: 70%;
    align-items: flex-start;
}

.payment-half-width-col {
    display: inline-flex;
    width: 50%
}
.cards-row {
    display: flex;
    flex-direction: row;
}

.open-pay-logo-column {
    width: 25%;
    align-items: center;
    img {
        max-width: 80%;
    }
    border-right: 1px solid #7f7f7f;
}

.open-pay-encryption-column {
    display: flex;
    flex-direction: row;
    width: 75%;
    align-items: center;
}
