@use "styles/colors";
@use "styles/typography";
.container {
    margin: auto;
    width: fit-content;
}

.info {
    display: flex;
    align-items: flex-end;
    .query {
        font-size: typography.$header-s;
        font-weight: typography.$bold;
        margin-right: 5px;
    }
    .results-number {
        font-size: typography.$body-s;
        color: colors.$color-text-disabled;
        position: relative;
        top: -2px;
    }
}

.pointer{
    cursor: pointer;
}

@media (min-width: 1300px) {
    .container{
        min-height: 550px;
    }
}