@use "styles/colors";
@use "styles/typography";

.container {
  padding: 56px;
  text-align: center;
}

.container-no-padding {
  text-align: center;
}

.popup {
  .popup-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popup-text {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
  }
  .popup-title {
    font-size: typography.$body-l;
    font-weight: typography.$bold;
    margin-bottom: 8px;
  }
  .popup-button {
    display: flex;
    justify-content: flex-end;
    Button {
      font-weight: typography.$bold;
    }
  }
}
