.sub-label {
    font-size: 12px;
}

.offer-head {
    margin-bottom: 8px;
}

.eta {
    font-size: 12px;
}

.eta-green-color {
    color: green;
    float: right;
}

.eta-red-color {
    color: red;
    float: right;
}

.card-body {
    cursor: pointer;
}