@use "styles/typography";
.small-subtitle {
    font-weight: typography.$bold;
    font-size: typography.$header-s;
}

.eta {
    font-size: 12px;
}

.eta-green-color {
    color: green;
    margin-left: 5px;
}

.eta-red-color {
    color: red;
    margin-left: 5px;
}

.check-divider {
    margin: 0px -15px;
}