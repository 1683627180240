@use "styles/colors";
@use "styles/typography";

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  .step-container {
    height: 64px;
    width: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 8px 8px 0px rgba(153, 153, 153, 0.15);
    border-radius: 32px;
    justify-content: center;
    color: colors.$default;
    z-index: 2;
    .selected {
      color: colors.$primary;
    }
  }
}
.button {
  width: fit-content;
  display: flex;
  border: none;
  border-radius: 80px;
  align-items: center;
  justify-content: center;
  padding: 11px 24px;
  background-color: transparent;
  color: colors.$default;
  font-size: typography.$body-s;
  padding: 0;
  p {
    margin: 0;
    color: colors.$neutral;
  }
}
