@use "styles/typography";
@use "styles/colors";

.buttons-wrapper {
  display: flex;
  gap: 12px;
}

.banner-message {
  margin-top: 12px;
  margin-bottom: 12px;
}

.banner-description {
  align-items: flex-start;
  font-weight: 500;
}

.banner-title {
  font-size: typography.$body-l;
  font-weight: typography.$bold;
  margin-bottom: 8px;
}
