@use "styles/colors";
@use "styles/typography";

.container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.map-container {
    height: 350px;
}

.map-info {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    margin: 20px 0px;
}

.error-text {
    margin-bottom: 10px;
    color: colors.$error;
    font-size: typography.$body-s;
}

.error-border {
    border: solid 2px colors.$error;
}

.error-border:focus-within {
    outline: none !important;
    border: solid 2px colors.$error;
}