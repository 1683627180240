@use "styles/colors";
@use "styles/typography";
.container {
  margin: 37px 0px;
  p {
    margin: 0;
  }
}

.title-container {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.primary {
  font-size: typography.$body-m;
  font-weight: typography.$bold;
}

.secondary {
  font-size: typography.$body-l;
}
