@use "styles/typography";
@use "styles/colors";

.black {
    background-color: #000000;
    border: 0.5px solid #000000;
}

.grey {
    background-color: #E6E6E6;
    border: 0.5px solid #E6E6E6;
}

.no-margin {
    padding: 0px;
    margin: 0px;
}

.vertical-left {
    .vertical-grey {
        border-left: 1px solid colors.$color-semantic-primary;
        padding-left: 5px;
    }

    .vertical-black {
        border-left: 1px solid black;
        padding-left: 5px;
    }
}

.vertical-right {
    .vertical-grey {
        border-right: 1px solid colors.$color-semantic-primary;
        padding-right: 5px;
    }

    .vertical-black {
        border-right: 1px solid black;
        padding-right: 5px;
    }
}