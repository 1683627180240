@use "styles/colors";
@use "styles/typography";

.grey {
  color: grey !important;
}
.price-line {
  display: flex;
  justify-content: space-between;
}

.unit-price {
  align-items: center;
  background-color: colors.$color-background-text;
  border-radius: 4px;
  font-size: 12px;
  font-weight: typography.$bold;
  line-height: 20px;
  .price {
    font-size: typography.$body-m;
  }
}

.price-summary {
  margin-top: 12px;
  .total-price {
    align-items: start;
    font-size: 16px;
    font-weight: typography.$bold;
    .total {
      font-size: 28px;
    }
  }

  .info {
    text-align: right;
    font-size: typography.$body-m;
    line-height: 22px;
    .green {
      color: colors.$success-300;
    }
  }
}
.button {
  Button {
    margin-top: 16px;
    width: 100%;
  }

  .button-content {
    display: flex;
    align-items: center;
  }
}
