@use "styles/typography";
@use "styles/colors";
.product-props-container {
  .title {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
  }
  .values-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 16px;
  }
}
