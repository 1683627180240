@use "styles/typography";
@use "styles/colors";
*,
*::before,
*::after {
  box-sizing: border-box;
}

.details-container {
  display: flex;
  gap: 24px;
  .images-container {
    width: 33%;
    display: flex;
    .carousel-container {
      width: 100%;
    }
  }
  .properties-container {
    width: 33%;
  }
  .summary-container {
    width: 33%;
    .price-container {
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 1360px) {
  .carousel-container {
    min-height: 550px;
  }
}
