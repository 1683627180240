@use "styles/typography";
@use "styles/colors";

.container {
  color: colors.$color-text-02;

  .margin-title {
    margin-bottom: 32px;
    margin-top: 40px;
  }
}

.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-title {
  font-size: typography.$header-s;
  font-weight: typography.$bold;
}

.medium-title {
  font-size: typography.$header-m;
  font-weight: typography.$bold;
}

.large-title {
  font-size: typography.$header-m;
  font-weight: typography.$bold;
}

@media (min-width: 1300px) {
  .large-title {
    font-size: typography.$header-l;
  }
}
