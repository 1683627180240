@use "styles/typography";
@use "styles/colors";

.details-container {
  display: flex;
  gap: 28px;
  .images-container {
    width: 33%;
    display: flex;
    .carousel-container {
      width: 100%;
    }
  }

  .product-props-container {
    width: 33%;

    .title-loading {
      height: 20px;
      width: 200px;
    }

    .subtitle-loading {
      height: 20px;
      width: 100%;
    }

    .values-container-loading {
      height: 25px;
      width: 60px;
      display: inline-block;
      margin-top: 5px;
      margin-right: 20px;
    }

    .values-title-loading {
      height: 20px;
      width: 60px;
      margin-top: 10px;
    }
  }
}

.summary {
  display: flex;
  width: 33%;
  flex-direction: column;
  align-items: center;

  .price-loading {
    height: 35px;
  }

  .price-box {
    .quantity-container {
      font-size: typography.$body-l;

      .quantity-title-loading {
        height: 25px;
        width: 26vw;
        margin-top: 10px;
      }

      .quantity-label-loading {
        height: 20px;
        width: 100px;
        margin-top: 10px;
      }

      .quantity-input-loading {
        height: 35px;
        width: 100%;
        margin-top: 5px;
      }

      .quantity-button-loading {
        height: 30px;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.loading {
  background: linear-gradient(-45deg, #ffffff, rgb(219, 214, 214));
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 1360px) {
  .carousel-container {
    min-height: 550px;
  }
}
