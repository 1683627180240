@use "styles/colors";
@use "styles/typography";

.search-container {
  z-index: 1;
  width: 80%;
  max-width: 1080px;

  .search-bar {
    margin: auto;
    background-color: colors.$white;
    border-radius: 80px;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    width: 100%;

    input {
      flex: 1;
      border: 0;
      outline: none;
      padding: 0;
      font-size: typography.$header-s;
      overflow: auto;
      height: max(20px);
    }

    .search {
      color: black;
    }
  }
}

.search-container-navbar {
  z-index: 1;
  width: 100%;

  .search-bar {
    margin: auto;
    background-color: colors.$secondary-hover;
    border-radius: 80px;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    width: 100%;
    height: 40px;

    input {
      flex: 1;
      border: none;
      outline: none;
      padding-right: 20px;
      font-size: 16px;
      overflow: auto;
      background-color: colors.$secondary-hover;
      color: colors.$neutral-400;
    }

    ::placeholder {
      color: colors.$neutral-400;
    }

    .search {
      color: colors.$white;
    }
  }
}
