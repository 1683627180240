@use "styles/typography";
@use "styles/colors";

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .large-image {
    width: 100%;

    .main-image {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 8px;
    }

    .fallback-image {
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: 100% 40%;
      border-radius: 8px;
      background-color: colors.$color-background-02;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.carousel {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tabs {
  width: 100%;
}

.carousel-images-container {
  width: 80%;

  .carousel-images {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      Button {
        padding: 0;
        margin: 0;
      }
    }

    .slide img {
      width: 100%;
      max-width: 80px;
      border-radius: 4px;
    }

    .slide-loading {
      height: 60px;
      width: 60px;
    }

    .slide-circle-loading {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      margin-top: 10px;
    }
  }
}

.arrow-button {
  min-width: 37px;

  Button {
    padding: 10px;
    font-size: typography.$body-l;
  }

  .icon {
    font-size: typography.$body-l;
  }
}

.loading-image {
  width: 100%;
  height: 400px;
}

.loading {
  background: linear-gradient(-45deg, #ffffff, rgb(219, 214, 214));
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
