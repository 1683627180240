@use "styles/colors";
@use "styles/typography";

.container {
  Button {
    margin: auto;
  }
  img {
    margin-top: -25px;
  }
}
