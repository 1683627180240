@use "styles/colors";

.container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;
}

.form-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
}

.submit-button-container {
  align-self: flex-end;
}