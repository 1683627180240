@use "styles/colors";
@use "styles/typography";

.header {
  height: 72px;
  width: 100%;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 24px 4.782vw;

  .login-shopping-cart-container {
    display: flex;
    gap: 96px;

    .shopping-cart-icon-empty {
      display: flex;
      color: colors.$white;
      margin-top: 3px;
    }
    .shopping-cart-icon {
      display: flex;
      color: colors.$white;
      .shopping-cart-count-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .shopping-cart-count-text-wrapper {
          display: flex;
          width: 12px;
          height: 12px;
          border-radius: 200px;
          background: colors.$warning-200;
          top: 16px;
          border: solid 1px colors.$secondary;
          position: absolute;
          z-index: 1;
          .shopping-cart-count-text {
            color: #000;
            display: flex;
            width: var(--space-12, 12px);
            height: var(--space-12, 12px);
            flex-direction: column;
            justify-content: center;
            font-family: Inter;
            font-size: 8px;
            font-weight: 600;
          }
        }
      }
    }

    .login-container {
      width: fit-content;

      .username {
        font-size: 16px;
        padding: 10px;
        color: colors.$white;
      }
    }
  }
}

.clean {
  .logo-container {
    margin-right: auto;
  }
}

.color {
  .logo-container {
    margin-left: -8px;
  }

  .search-bar {
    display: flex;
    justify-content: center;
    width: 50%;
  }
}

.logo {
  width: 164px;
}

.color {
  background-color: colors.$secondary;
}

.clean {
  background-color: transparent;
  position: absolute;
}

.cart-tooltip-children {
  width: 250px;
  height: 230px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px;

  .cart-tooltip-text {
    font-size: typography.$body-m;
  }

  .cart-tooltip-img {
    margin: 10px;
    width: 200px;
  }

  .cart-tooltip-navigate-button {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    Button {
      font-size: typography.$body-s;
    }
  }
}

.title {
  font-weight: typography.$bold;
  font-size: typography.$header-s;
}

.tooltip-log {
  min-width: 150px;
  width: 15vw;

  Button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: typography.$bold;
    p {
      font-size: typography.$body-m;
    }
  }
}

@media (min-width: 1300px) {
  .tooltip-log {
    Button {
      font-size: typography.$header-s;
    }
  }
}
