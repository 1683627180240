@use "styles/colors";
@use "styles/typography";
.stepper-wrapper {
  display: flex;
  flex-direction: column;
}

.horizontal-stepper-wrapper {
  width: 100%;
  flex-direction: row;
  .stepper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .stepper-selector {
      width: 60vw;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.vertical-stepper-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  .stepper {
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    justify-content: space-around;
    .stepper-selector {
      height: 80%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.title {
  color: colors.$white;
  font-size: typography.$header-s;
  font-weight: typography.$semi-bold;
}

.vertical-line {
  width: 2px;
  background-color: colors.$white;
  height: 62%;
  margin-left: 7px;
  margin-top: 8px;
  position: absolute;
}

.horizontal-line {
  height: 7px;
  width: 60vw;
  position: absolute;
  background-color: colors.$color-background-02;
  margin-top: 27px;
}
