.container {
  Button {
    margin: auto;
  }
  img {
    margin-top: -25px;
  }
}

.loading-button {
  min-width: 52%;
  padding: none;
}
