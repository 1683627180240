@use "styles/typography";
@use "styles/colors";
.product {
  width: 100%;
  p {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: typography.$body-l;
    margin-bottom: 4px;
  }
  .body {
    display: flex;
    justify-content: space-between;
    .image {
      width: 64px;
      height: 64px;
    }
    .properties {
      display: flex;
      width: 80%;
      img {
        margin-right: 8px;
      }
      .name {
        font-size: typography.$body-s;
      }
      .property {
        font-size: typography.$body-s;
        color: colors.$light;
      }
    }
  }
}
