@use "styles/typography";
@use "styles/colors";


.card-logo-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;

    &.credit-card-logo-container {
        width: 40%;
        margin: 0% 4%;
    }
    &.debit-card-logo-container {
        width: 19%;
        margin: 0% 1%;
    }
    &.bbva-debit-card-logo-container {
        display: inline-block;
        text-align: center;
        width: 19%;
        height: 40px;
        margin: 0px 1% 0px 1%;
        img {
            margin: 0em 0  0.5em 0em;
            max-width: 90%;
            height: 70%;
        }
    }

    img {
        width: 80%;
        max-height: 90%;
        margin: 0.5em 0.2em;
    }
}