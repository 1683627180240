@use "styles/colors";
@use "styles/typography";
.container {
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
}

.close {
  position: absolute;
  right: 10px;
  width: 25px;
  top: 10px;
}

.popup-trigger {
  cursor: pointer;
  font-weight: typography.$bold;
  color: colors.$text-link;
}

.react-pdf__Page {
  width: 100% !important;
  height: 100% !important;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
