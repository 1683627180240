.container {
  display: flex;
  flex-direction: row;
}

.form-container {
  width: 75%;
  margin-right: 5%;
}

.popup-content {
  margin-top: 14px;
  a {
    padding: 0;
  }
}
