.tabs {
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tabs::-webkit-scrollbar {
    display: none;
}