@use "styles/typography";
@use "styles/colors";
.icon {
  margin-left: 7px;
  font-size: typography.$body-l;
}

.body {
  display: flex;
  justify-content: space-between;
  .payment-steps {
    width: 62%;
    .question {
      font-size: typography.$body-s;
    }
  }
}

.tooltip-text {
  width: 230px;
  height: 80px;
  margin-left: 20px;
  .copy-email {
    color: colors.$accent;
    font-size: typography.$body-l;
  }
}

.payment-container {
  display: flex;
}

.payment-box {
  margin-left: 3%;
}

@media (min-width: 1300px) {
  .medium-subtitle {
    font-size: typography.$header-m;
  }
}
