@use "styles/colors";
@use "styles/typography";
.footer {
  background-color: colors.$secondary;
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .info-text {
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 40%;
    margin-left: 3%;
    .info-text-container {
      margin-right: 100px;
    }
    .buttons Button {
      font-size: typography.$body-s;
      margin-top: 9px;
    }
    .buttons p {
      margin: 0;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .tooltip-text {
    width: 300px;
    height: 110px;
    margin-left: 20px;
    .copy-email {
      color: colors.$accent;
      font-size: typography.$body-l;
    }
  }

  .title {
    p {
      margin: 0;
    }
    font-weight: typography.$bold;
    font-size: typography.$header-s;
  }
}
