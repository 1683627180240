@use "styles/typography";

.view {
  height: 100vh;
  background-color: white;
  position: relative;
  z-index: 2;
  padding-top: 1px;

  .card {
    padding: 24px;
    box-shadow: 0px 16px 32px 0px #f0f0f0;
    width: 800px;
    margin: 40px auto auto;

    .status-window {
      padding: 12px;
      width: 100%;
      background-color: #fbfbfb;

      .icon-container {
        text-align: center;
        padding: 20px 10px;

        img {
          height: 96px;
        }

        .title {
          font-size: typography.$header-m;
          font-weight: typography.$bold;
        }
      }
    }

    .status-context {
      font-size: typography.$body-l;
      margin-bottom: 36px;
    }

    .action-container {
      text-align: right;
      Button {
        margin-left: auto;
      }
    }
  }
}
