@use "styles/colors";
@use "styles/typography";

.category-arrow-container {
  font-weight: typography.$bold;
  font-size: typography.$header-m;
  display: flex;
}

.question-content-container {
  margin-top: 25px;

  .question {
    font-size: typography.$body-l;
  }

  .content {
    margin-top: 14px;
    font-size: typography.$body-m;
    white-space: pre-wrap;
  }
}
