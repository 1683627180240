@use "styles/colors";
@use "styles/typography";

.tab {
  Button {
    margin: 0;
    min-width: 205px;
    font-size: typography.$body-l;

    p {
      margin: 0;
      white-space: nowrap;
    }
  }
}

.content {
  min-width: 100px;

  .secondary.active {
    background-color: colors.$secondary;
    color: colors.$white;
  }
}

@media (min-width: 1360px) {
  .tab {
    Button {
      min-width: 160px;
    }
  }
}

@media (min-width: 1800px) {
  .tab {
    Button {
      min-width: 280px;
    }
  }
}
