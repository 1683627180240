@use "styles/typography";
@use "styles/colors";
.price-container {
    width: 25vw;
    max-width: 417px;
    min-width: 230px;
    p {
        margin: 0;
    }
    Button {
        margin-bottom: 16px;
        width: 100%;
    }
}

.sticky {
    position: sticky;
    top: 5vw;
}

.medium-subtitle {
    font-weight: typography.$bold;
    font-size: typography.$header-s;
    margin-bottom: 15px;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}