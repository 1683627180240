@use "styles/colors";
@use "styles/typography";

.header-title {
  margin-bottom: 24px;
  color: colors.$white;
  font-size: typography.$header-m;
  font-weight: typography.$bold;
  z-index: 1;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-content::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.65) 19.38%,
    rgba(0, 0, 0, 0.3) 52.15%,
    rgba(0, 0, 0, 0.05) 88.61%
  );
}

.catalog {
  display: flex;
  align-items: flex-start;

  .side-menu {
    width: 17%;
  }

  .products-container {
    width: 83%;
    display: flex;
    flex-wrap: wrap;
  }
}

.see-more-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 5%;

  .see-more {
    color: colors.$accent;
  }
}

.pointer {
  cursor: pointer;
}

.home-image {
  height: 370px;
}
