@use "styles/colors";

.bgImage {
  height: 100%;
  object-fit: cover;
  object-position: 100% 30%;
  width: 100%;
}

.horizontal {
  height: 100%;
  width: 100%;
  position: relative;
}

.vertical {
  height: 100%;
  width: 30%;
  max-width: 448px;
}

.content {
  height: inherit;
  text-align: center;
  width: inherit;
}

.top {
  position: absolute;
  top: 0%;
  padding: 0 60px;
}

.left {
  position: absolute;
  left: 0%;
}

.right {
  position: absolute;
  right: 0%;
}

.middle {
  position: absolute;
  top: 0;
  left: 0;
}

.primary {
  background-color: colors.$secondary;
}

@media only screen and (max-width: 1300px) {
  .vertical {
    display: none;
  }
}
