@use "styles/colors";
@use "styles/typography";

.mail {
    color: colors.$text-link;
    background-color: transparent;
}

.mail:visited {
    color: colors.$text-link;
    background-color: transparent;
}

.mail:active {
    color: colors.$text-link;
}

.not-underlined {
    text-decoration: none;
}