@use "styles/typography";
.description-container {
  .section-title {
    margin: 16px 0px 8px;
    font-size: typography.$header-s;
    font-weight: typography.$bold;
    text-transform: initial;
  }
  .section-title:first-letter {
    text-transform: uppercase;
  }
  .description {
    margin-bottom: 60px;
    .section-content {
      font-size: typography.$body-m;
      line-height: 24px;
      .description-list {
        padding-left: 24px;
      }
      .list-item {
        line-height: 24px;
      }
    }
  }
}
