@use "styles/typography";
@use "styles/colors";

.list-products-column {
  margin-top: 10px;
  width: 60%;
  align-items: flex-start;

  p {
    margin-bottom: 0px;
  }
}

.quantity-price {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
}

.quantity-column {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: colors.$color-background-02;
}

.price-column {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.detail-delete-container {
  margin-bottom: 10px;

  .detail {
    font-size: typography.$body-s;
    color: colors.$color-text-disabled;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 20px;

    p {
      margin-top: 5px;
    }
  }

  .icon {
    font-size: typography.$body-l;
  }

  .delete {
    margin-top: auto;

    Button {
      padding-bottom: 0;
      font-size: typography.$body-l;
    }
  }
}

.check-divider {
  margin: 0px -15px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.characteristics {
  padding-right: 5px;
  border-right: 1px solid rgb(174, 176, 174);
}
