$default: #666666;

$neutral: #333333;
$neutral-200: #696969;
$neutral-400: #bbbbbb;
$neutral-700: #252525;

$accent: #054ab0;

$primary: #ff6b00;
$primary-hover: #c75004;

$secondary: #1f1f1f;
$secondary-hover: #393939;

$white: #ffffff;

$light: #616c7a;
$light-blue: #ebf3ff;

$color-background-02: #f2f2f2;
$color-background-text: #fbfbfb;

$color-text-02: #333333;
$color-text-disabled: #808080;
$text-link: #0058e1;
$text-link-hover: #003b9c;

$color-button-disabled: #e9e9e9;

$color-semantic-primary: #e6e6e6;

$success-300: #4ba564;
$color-success-active: #008863;
$color-success-disable: #32d2a7;

$error: #c9423c;
$error-200: #d73a3d;
$color-error-active: #a63631;
$color-error-disable: #ff9590;

$warning-200: #ffb93c;
$info-200: #1175c9;
