@use "styles/colors";
@use "styles/typography";

.container {
    display: flex;
    flex-direction: column;
    margin: 18px 5% 0 0;
    width: 95%;
}

.input {
    height: 40px;
    border: solid 1px #666666;
    border-radius: 4px;
    margin-top: 8px;
    padding: 9px 16px;
}

.input-error:focus-within {
    outline: none !important;
    border: solid 2px colors.$error;
}

.error-text {
    margin-top: 10px;
    color: colors.$error;
    font-size: typography.$body-s;
}

.input-error {
    border: solid 1px colors.$error;
}

.input-error:focus {
    border-color: colors.$error;
}