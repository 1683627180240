@use "styles/colors";
@use "styles/typography";

$sidebar-width: 33%;

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0px;
  gap: 200px;
  width: $sidebar-width;
  max-width: 432px;
  background: #ffffff;
  border-right: 1px solid #e9e9e9;
  .profileImg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 28px;
    width: 100%;
    height: 124px;
    .profileImgText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      background: #1f1f1f;
      border-radius: 100px;
      .avatarText {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        user-select: none;
      }
    }
  }

  .navList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    min-width: 282px;
    width: 75%;
    .item {
      padding: 0px 24px;
      width: 100%;
      height: 36px;
      border-radius: 200px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;
      Button {
        width: 100%;
      }
      .wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: auto;
        .text {
          width: 100%;
          height: 28px;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #151515;
          user-select: none;
          white-space: nowrap;
        }
        .icon {
          color: #151515;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px;
          width: 20px;
          height: 20px;
        }
      }
      &:hover {
        background-color: #151515;
        color: #e9e9e9;
        .text {
          color: #e9e9e9;
          font-weight: 600;
        }
        .icon {
          color: #e9e9e9;
        }
      }
    }
    .itemActive {
      @extend .item;
      background: #151515;
      color: #e9e9e9;
      .text {
        @extend .text;
        color: #e9e9e9 !important;
        font-weight: 600 !important;
      }
      .icon {
        color: #e9e9e9 !important;
      }
    }
  }
}
