.full-width {
  width: 100%;
}

.half-width {
  width: 48%;
}

.medium {
  width: 66%;
}

.small {
  width: 30%;
}

.field-caption {
  padding: 0.4em 0em;
  color: #7f7f7f;
  font-size: 0.8em;
}

*:disabled {
  background-color: #efefef;
}
