@use "styles/colors";
@use "styles/typography";

.image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
}

.image-content::after {
  content: "";
  background: rgba(45, 88, 35, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.65) 19.38%,
    rgba(0, 0, 0, 0.3) 52.15%,
    rgba(0, 0, 0, 0.05) 88.61%
  );
}

.image-text {
  display: flex;
  justify-content: center;
  font-size: typography.$header-l;
  font-weight: typography.$bold;
  z-index: 1;
  color: colors.$white;
  text-shadow: 0 1px black;
}

.about-us-first-content {
  margin-top: 28px;
  font-size: typography.$body-m;
  text-align: left;
}

.about-us-second-content {
  font-size: typography.$body-m;
  text-align: left;
}

.about-us-image {
  height: 120px;
}
