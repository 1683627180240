@use "styles/typography";
.summary-card {
    margin-top: 36px;
    margin-bottom: 24px;
    .total-price {
        font-size: typography.$header-s;
        font-weight: typography.$bold;
    }
    .discount {
        font-size: 12px;
        color: green;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .description {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.product {
    margin-bottom: 24px;
}

.right-wrapper {
    float: right;
    .data-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .total-wrapper {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
}