@use "styles/colors";
@use "styles/typography";

.item {
  Button {
    width: 100%;
    font-size: typography.$body-m;

    p {
      width: 100%;
      text-align: left;
    }
  }
}
