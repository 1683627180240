@use "styles/colors";
@use "styles/typography";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.input {
  padding: 9px 16px;
  height: 65px;
  width: 100%;
  border: solid 1px #666666;
  border-radius: 4px;
  margin-top: 8px;
  resize: none;
}

.input-error {
  border: solid 1px colors.$error;
}

.input-error:focus-within {
  outline: none !important;
  border: solid 2px colors.$error;
}

.error-text {
  margin-top: 10px;
  color: colors.$error;
  font-size: typography.$body-s;
}
