@use 'styles/colors';
@use 'styles/typography';
.tooltip-style {
    background-color: colors.$white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0px 8px 8px rgba(54, 54, 54, 0.85);
    width: fit-content;
    z-index: 2;
}

.close {
    display: flex;
    justify-content: flex-end;
}