@use "styles/colors";
@use "styles/typography";

.notification {
  position: absolute;
  top: 0px;
  z-index: 5;
  padding: 15px;
  background-color: colors.$white;
  border-radius: 8px;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.16),
    0px 2px 24px rgba(51, 51, 51, 0.15);
}

.large {
  width: 100%;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  width: 25px;
}

.notification-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.with-top {
  z-index: 1;
  top: 270px;
}

.without-top {
  z-index: 1;
  position: fixed;
  top: 0px;
}
