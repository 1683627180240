@use "styles/colors";

.success {
  color: colors.$success-300;
}

.error {
  color: colors.$error-200;
}

.refresh {
  padding: 5px;
  border-radius: 154px;
  background: var(--success-300, colors.$success-300);
}
