@use "styles/colors";
@use "styles/typography";

a {
  color: colors.$text-link;
  text-decoration: none;
  background-color: transparent;
  padding: 0 5px;
  font-size: typography.$body-m;
}

a:hover {
  color: colors.$text-link-hover;
  text-decoration-line: underline;
}
