@use "styles/colors";
@use "styles/typography";

.banner-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.banner {
  align-items: center;
  background: colors.$white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px #f0f0f0;
  color: colors.$secondary-hover;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  min-height: 44px;
  padding: 4px 32px;
  width: 100%;
  /* font */
  font-size: typography.$body-m;
  font-weight: typography.$semi-bold;
  line-height: 22px;
}

.icon-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.neutral {
  border-bottom: 2px solid colors.$neutral-200;
}
.error {
  border-bottom: 2px solid colors.$error-200;
}
.warning {
  border-bottom: 2px solid colors.$warning-200;
}
.info {
  border-bottom: 2px solid colors.$info-200;
}
.success {
  border-bottom: 2px solid colors.$success-300;
}

.neutral-icon {
  color: colors.$neutral-200;
}
.error-icon {
  color: colors.$error-200;
}
.warning-icon {
  color: colors.$warning-200;
}
.info-icon {
  color: colors.$info-200;
}
.success-icon {
  color: colors.$success-300;
}

.close {
  color: colors.$neutral-400;
  line-height: 0;
}
