@use "styles/typography";
@use "styles/colors";

$sidebar-width: 33%;

.body {
  min-height: 100vh;
  display: flex;
}

.content {
  width: 100% - $sidebar-width;
  display: grid;
}

.backHome {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 24px;
  gap: 20px;
  width: 100%;
  height: 108px;
  background: #fbfbfb;
  border-bottom: 1px solid #e9e9e9;
}

.backHomeText {
  width: 170px;
  height: 28px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #151515;
  cursor: pointer;
  user-select: none;
}

.section {
  width: 75%;
  display: flex;
  justify-self: center;
  margin-bottom: 20px;
}
