@use "styles/colors";
@use "styles/typography";

.container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.right-button {
  margin: auto 0 auto auto;
}

.buttons-container {
  display: flex;
  margin: auto auto 0;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}

.back-button {
  Button {
    padding: 11px;
  }

  .back-icon {
    font-size: typography.$body-l;
  }
}

.form-description {
  color: colors.$color-text-disabled;
  font-weight: typography.$bold;
}
